/**
 * This bootstrap file is used for both frontend and backend
 */

import _ from 'lodash'
import axios from 'axios'
import Swal from 'sweetalert2';
import $ from 'jquery';
//import '../../node_modules/datatables.net-dt/js/dataTables.dataTables.min.js';
import './backend/plugin/jquery.dataTables.min.js';
// import './backend/plugin/dataTables.buttons.min.js';
// import jsZip from  './backend/plugin/jszip.min.js';
// import pdfMake from './backend/plugin/pdfmake.min.js';
// import pdfFonts from './backend/plugin/vfs_fonts.js';
// import './backend/plugin/buttons.html5.min.js';
// import './backend/plugin/buttons.print.min.js';
import 'popper.js'; // Required for BS4
import 'bootstrap';/*import 'datatables-bootstrap';*/
import 'jquery-datetimepicker';
import './backend/jquery-ui/js/jquery-ui.min.js';
import 'jquery-validation';
//import './backend/jquery-ui/jquery.ui.datepicker.validation.min.js';
import '../../node_modules/select2/dist/js/select2.full.min.js';
/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
window.$ = window.jQuery = $;
window.Swal = Swal;
window._ = _; // Lodash
$.datetimepicker.setLocale('fr');
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

// pdfMake.vfs = pdfFonts.pdfMake.vfs;
// window.JSZip = jsZip;
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
